.header {
	padding-top: 10px;
	padding-bottom: 13px;
	position: fixed;
	width: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 10;
	&-menu {
		&__list {
			padding-left: 0;
			margin-bottom: 0; }
		&__item {
			display: inline-block;
			padding: 0 20px;
			a {
				color: #000;
				font-style: normal;
				font-weight: bold;
				font-size: 13px;
				line-height: 15px;
				letter-spacing: 0.03em;
				text-transform: uppercase;
				padding-bottom: 5px;
				&:hover {
					text-decoration: none;
					border-bottom: 4px solid #F0610C; } } } }
	&-white {
		background: #fff;
		box-shadow: 0 0 7px rgba(0,0,0,.25); }
	&-phone a {
		color: #000;
		font-size: 18px;
		line-height: 27px; } }
