.work {
	padding-top: 73px;
	padding-bottom: 79px;
	background: #F7F8FA;
	.section-title {
		margin-bottom: 50px; }
	&-item {
		background: #F0610C;
		border-radius: 10px;
		padding: 13px 18px 16px 24px;
		height: 100%;
		color: #fff;
		&__head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 25px; }
		&__number {
			font-weight: bold;
			font-size: 60px;
			line-height: 70px;
			text-transform: uppercase;
			color: #F9833D; }
		&__title {
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			margin-bottom: 14px; }
		&__descr {
			font-size: 15px;
			line-height: 21px; } }
	&-col {
		max-width: 338px;
		z-index: 1; } }
.work .line::after {
	content: "";
	display: inline-block;
	background: #FF9153;
	width: 106px;
	height: 2px;
	position: absolute;
	top: 50%;
	right: -100px; }
.work-col::before {
	position: absolute;
	width: 228px;
	height: 239px;
	background: url('../img/work/plan.png') center no-repeat;
	content: '';
	display: block;
	z-index: -1;
	top: -28px;
	left: -15px; }
.work-col:first-child:before {
	top: 25px;
	left: -20px; }
.work-col:last-child:before {
	top: -34px;
	right: -18px;
	left: auto; }
