table {
	width: 100%; }
td {
	border: 1px solid #f59c15;
	text-align: center;
	padding: 5px 10px;
	font-size: 14px; }
.size-row-active td {
	background: #f37f10;
	color: #fff; }
.size-row {
	cursor: pointer; }
