@import "bootstrap";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel.scss";
@import "../../node_modules/fancybox/dist/scss/jquery.fancybox.scss";
button, button:visited, button:focus, button:active, button:hover, select, select:visited, select:focus, select:active, input:hover, input, input:visited, input:focus, input:active, input:hover, area, map {
	outline: 0 none !important; }
button:hover, a:hover {
	cursor: pointer; }
body {
	font-family: Roboto; }
.section-title {
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #000; }
@media (min-width: 992px) {
	.container {
    max-width: 990px; } }
@media (min-width: 1200px) {
	.container {
  	max-width: 1200px; }
	.col-small {
		flex: 0 0 14%;
		max-width: 14%; }
	.col-big {
		flex: 0 0 19.333%;
		max-width: 19.333%; } }
.fancybox-inner-height {
	height: auto !important; }
@import 'buttons';
@import 'form';
@import 'header';
@import 'main-section';
@import 'products';
@import 'advantages';
@import 'delivery';
@import 'work';
@import 'questions';
@import 'footer';
@import 'popup';
@import 'mobile-menu';
@import 'table';
@import 'media';
.mb0 {
	margin-bottom: 0px; }



