.delivery {
	background: #e9f0f3;
	padding-top: 64px;
	padding-bottom: 75px;
	.container {
		position: relative; }
	.section-title {
		text-align: center;
		padding-left: 15px;
		padding-right: 15px; }
	&-map {
		text-align: center;
		position: relative;
		width: 981px;
		margin-left: auto;
		margin-right: auto;
		height: 493px;
		background: url('../img/map-1.png') center no-repeat;
		background-size: contain;
		margin-bottom: -171px; }
	&-form {
		background: linear-gradient(180deg, #F59C15 0%, #F05E0C 100%);
		border-radius: 10px;
		padding: 40px 73px 7px 58px;
		&__item {
			margin-bottom: 30px; }
		&__title {
			font-weight: bold;
			font-size: 25px;
			line-height: 21px;
			text-align: center;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #FFF;
			margin-bottom: 39px; } } }
.point {
	width: 20px;
	height: 27px;
	background: url('../img/loc.png') no-repeat;
	position: absolute; }
.point-1 {
	top: 72px;
	left: 242.72px; }
.point-2 {
	top: 216px;
	left: 155.72px; }
.point-3 {
	top: 269px;
	left: 201.72px; }
.point-4 {
	top: 188px;
	left: 304.72px; }
.point-5 {
	top: 242px;
	left: 421.72px; }
.point-6 {
	top: 235px;
	right: 303.72px; }
.point-7 {
	top: 71px;
	right: 142.72px; }
