.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: opacity .25s ease-in-out,visibility 0s ease-in-out .25s; }
.overlay-active {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s ease-in-out; }
.mobile-menu {
	&__wrap {
		top: 0;
		left: 0;
		z-index: 999;
		box-shadow: 0px 4px 4px #929A99;
		background: #f7f8fa;
		width: 260px;
		height: 100%;
		border: none;
		transform: translateX(-300px);
		transition: transform .25s ease-in-out;
		position: fixed;
		padding: 78px 48px; }
	&-btn {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 26px;
		span {
			display: block;
			width: 33px;
			height: 4px;
			background: #F05E0C; } }
	&__button .btn {
		font-size: 12px;
		line-height: 11px;
		padding-top: 12px;
		padding-bottom: 14px; }
	&__list {
		padding: 0;
		margin-bottom: 31px; }
	&__item {
		text-align: center;
		list-style-type: none;
		a {
			font-weight: bold;
			font-size: 15px;
			line-height: 50px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #000; } } }

.close-menu {
	top: 21px;
	left: 23px; }
.show-menu {
  transform: translateX(0); }
