.popup {
	display: none;
	background: #FFFFFF;
	box-shadow: 0px 0px 18px #D3D4D6;
	border-radius: 10px;
	padding: 66px 37px 52px;
	&-title {
		text-align: center;
		margin-bottom: 17px; }
	&-subtitle {
		margin-bottom: 33px;
		font-size: 16px;
		line-height: 21px;
		color: #000; }
	&-form {
		&__item {
			margin-bottom: 25px; } }
	.checkbox-label::before {
		background: #F7F8FA; }
	&-img {
		text-align: center;
		margin-bottom: 13px; }
	&-order {
		display: none;
		margin-bottom: 30px;
		&__size {
			display: flex; }
		span {
			display: inline-block;
			font-weight: bold; } } }
.fancybox-close {
	top: 22px;
	right: 25.42px;
	width: 22px;
	height: 22px;
	background-image: none;
	&::after {
		content: '';
		width: 25.15px;
		height: 3.05px;
		display: block;
		background: #F05E0C;
		transform: rotate(45deg);
		position: absolute;
		top: 50%; }
	&::before {
		content: '';
		width: 25.15px;
		height: 3.05px;
		display: block;
		background: #F05E0C;
		transform: rotate(-45deg);
		position: absolute;
		top: 50%; } }
.success {
	display: none; }
#popup-map {
	padding: 66px 0 52px; }
