.questions {
	padding-top: 20px;
	padding-bottom: 14px;
	background: url('../img/questions/bg.jpg') center no-repeat;
	background-size: cover;
	.section-title {
		color: #F05E0C;
		margin-bottom: 20px; }
	&-subtitle {
		margin-bottom: 44px;
		font-size: 16px;
		line-height: 19px;
		color: #000; }
	&-form {
		&__item {
			margin-bottom: 37px; } } }

