.main {
	padding-top: 254px;
	padding-bottom: 182px;
	background: url('../img/main-bg.jpg') center no-repeat;
	position: relative;
	&-title h1 {
		font-weight: 900;
		font-size: 35px;
		line-height: 45px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		color: #000;
		margin-bottom: 23px; }
	&-subtitle {
		font-size: 18px;
		line-height: 27px;
		color: #000;
		margin-bottom: 34px; }
	&-button .btn {
		padding: 19px 38px; } }
.main .container::after {
	content: "";
	display: block;
	width: 163px;
	height: 156px;
	position: absolute;
	background: url("../img/bird.png") center;
	top: -135px;
	left: -138px; }
.main .container {
	position: relative; }
