.footer {
	background: #E9F0F3;
	padding-top: 31px;
	padding-bottom: 32px;
	&-title {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		color: #000;
		margin-bottom: 20px; }
	&-text {
		font-size: 16px;
		line-height: 25px;
		color: #000;
		margin-bottom: 14px;
		p {
			margin-bottom: 0px; } }
	&-logo {
		text-align: center;
		margin-bottom: 36px; } }
.bottom {
	background: #DAE7EC;
	padding-top: 8px;
	padding-bottom: 9px; }
