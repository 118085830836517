.products {
	padding-top: 53px;
	padding-bottom: 75px;
	background: #F7F8FA;
	&-tabs {
		margin-bottom: 51px; }
	.section-title {
		margin-bottom: 37px; } }
.product {
	background: #FFFFFF;
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	padding: 24px 45px 35px;
	&-wrap {
		opacity: 0;
		visibility: hidden;
		height: 0;
		transition: opacity .2s ease-out 0s, visibility .2s ease-out 0s; }
	&-active {
		opacity: 1;
		visibility: visible;
		height: auto;
		transition: opacity 1s ease-out 0s, visibility 1s ease-out 0s; }
	&-img {
		margin-bottom: 24px; }
	&-title {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		color: #000;
		margin-bottom: 22px; }
	&-characteristics {
		&__list {
			padding: 0;
			margin: 0; }
		&__item {
			border-radius: 5px 5px 0px 0px;
			padding: 10px 23px 11px 16px;
			font-size: 15px;
			line-height: 18px;
			color: #000;
			list-style-type: none; } }
	// &-price
	// 	text-transform: uppercase
	// 	margin-bottom: 32px
	// 	span
	// 		font-size: 38px
	// 		line-height: 45px
	// 		color: #F05E0C
	&-button {
		margin-top: 38px;
		text-align: center;
		.btn {
			padding-left: 59px;
			padding-right: 59px; } }
	&-advantage {
		display: flex;
		align-items:center {}
		margin-bottom: 25px;
		&__icon {
			flex-shrink: 0;
			margin-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 30px;
			img {
				flex-shrink: 0; } } }
	&-row {
		padding-bottom: 42px;
		border-bottom: 1px solid #e1e1e1; }
	&-application {
		padding-top: 38px;
		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column; }
		&__img {
			background: radial-gradient(50% 50% at 50% 50%, #F59C15 0%, #F05E0C 100%);
			width: 84px;
			height: 84px;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			img {
				flex-shrink: 0; } }
		&__text {
			text-align: center;
			font-size: 15px;
			line-height: 18px;
			color: #000; } } }
.product-characteristics__item:nth-child(odd) {
	background: #F1F2F6; }
.row-size {
	margin-right: -6px;
	margin-left: -6px; }
.col-size {
	padding-left: 6px;
	padding-right: 6px;
	margin-bottom: 13px; }
