.form-input {
	background: #FFFFFF;
	box-shadow: 0px 0px 4px #929A99;
	border-radius: 5px;
	font-family: Roboto;
	font-size: 16px;
	line-height: 19px;
	padding: 10px;
	width: 100%;
	border: none; }
.label {
	font-family: Roboto;
	font-size: 16px;
	line-height: 19px;
	color: #000;
	margin-bottom: 14px; }
.checkbox-label {
	font-family: Roboto;
	font-size: 12px;
	line-height: 18px;
	color: #000;
	margin-bottom: 0;
	display: flex;
	&::before {
		flex-shrink: 0;
		content: '';
		display: inline-block;
		background: #FFFFFF;
		border-radius: 5px;
		width: 22px;
		height: 22px;
		text-align: center;
		font-size: 18px;
		padding-top: 3px;
		margin-right: 13px; } }
.checkbox {
	display: none; }
.checkbox:checked + .checkbox-label::before {
	content: '\2714';
	color: #F05E0C; }
.icon-btn {
	width: 17px;
	height: 23px;
	background: url('../img/file.png') center no-repeat;
	margin-right: 14px;
	display: block;
	margin-top: -5px; }
.checkbox-label a {
	color: #000;
	text-decoration: underline; }
.delivery .success .section-title, .delivery .success .popup-subtitle {
	color: #fff;
	text-align: center; }
@media (min-width: 992px) {
	.delivery .success {
		padding-bottom: 35px; } }
