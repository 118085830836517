.advantages {
	padding: 54px 0;
	background: url('../img/about/bg.jpg') center no-repeat;
	background-size: cover;
	.section-title {
		color: #F05E0C;
		margin-bottom: 37px; }
	&-content {
		background: #fff;
		padding: 43px 35px 40px 27px;
		border-radius: 10px;
		margin-bottom: 40px; }
	&-item {
		display: flex;
		align-items: center;
		margin-bottom: 35px;
		&__icon {
			min-width: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 22px;
			img {
				flex-shrink: 0; } }
		&__title {
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #000;
			margin-bottom: 13px; }
		&__descr {
			font-size: 15px;
			line-height: 21px;
			color: #000; } }
	&-button a {
		display: flex;
		align-items:center {}
		max-width: 252px;
		padding-left: 27px;
		padding-right: 28px;
		&:hover {
			text-decoration: none;
			color: #fff; } } }
