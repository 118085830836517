button {
	font-family: Roboto; }
.btn {
	background: linear-gradient(90deg, #F05E0C 0%, #F59C15 100%);
	border-radius: 30px;
	border: none;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #FFF;
	padding-top: 15px;
	padding-bottom: 15px;
	&:hover {
		background: linear-gradient(270deg, #F05E0C 0%, #F59C15 100%);
		color: #FFF;
		text-decoration: none; }
	&:active {
		background: #F05E0C; } }
.tab-btn {
	border: 2px solid #FF9153;
	box-sizing: border-box;
	border-radius: 30px;
	background: transparent;
	color: #000;
	font-size: 16px;
	line-height: 19px;
	padding-top: 15px;
	padding-bottom: 15px;
	&:hover {
		background: linear-gradient(90deg, #F05E0C 0%, #F59C15 100%);
		color: #fff; } }
.tab-active .tab-btn {
		background: linear-gradient(90deg, #F05E0C 0%, #F59C15 100%);
		color: #fff;
		border: none; }
.size-btn {
	border: 1px solid #F59C15;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 6px;
	color: #000;
	background: transparent;
	font-size: 16px;
	line-height: 19px; }
.col-size-active .size-btn {
	background: #F59C15;
	color: #fff; }
.price-btn {
	background: #FFF;
	border-radius: 30px;
	border: none;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #F05E0C;
	&:hover {
		background: #FFD397; }
	&:active {
		background: #FFB855; } }
.wide-btn {
	display: block;
	width: 100%;
	text-align: center; }
.link-btn {
	font-family: Roboto;
	font-weight: normal;
	font-size: 16px;
	text-decoration-line: underline;
	color: #F05E0C;
	&:hover {
		color: #F05E0C;
		text-decoration: underline; } }
.phone-btn {
	color: #000;
	&:hover {
		color: #000;
		text-decoration: underline; } }
.mobile-tab {
	margin-bottom: 30px;
	&__icon {
		display: block;
		width: 15px;
		height: 9.26px;
		background: url("../img/arrow-up.png") center; } }
.mobile-tab .tab-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 33px;
	padding-right: 27px; }
.mobile-tab-active .tab-btn {
	background: linear-gradient(90deg, #F05E0C 0%, #F59C15 100%);
	color: #fff;
	border: none;
	.mobile-tab__icon {
		background: url("../img/arrow.png") center; } }
.header-button .btn {
	padding: 10px 22px 11px; }
