@media (max-width: 1450px) {
	.main .container::after {
		left: -78px; } }
@media (max-width: 1320px) {
	.main .container::after {
		display: none; } }
@media(max-width: 1250px) {
	.work-col:first-child::before {
		left: 0px; }
	.work-col:last-child::before {
		right: 0px; } }
@media (max-width: 1199px) {
	.header {
		&-phone a {
			font-size: 16px; }
		&-menu__item {
			padding: 0 15px; } }
	.delivery {
		// &-map
		// 	width: 100%
		// 	height: 317px
		&-form {
			padding: 40px 30px 7px; } }
	.work {
		&-col {
			max-width: 310px; }
		.line:after {
			width: 50px;
			right: -40px; } } }
@media (max-width: 991px) {
	.size-btn {
		font-size: 13px;
		line-height: 15px; }
	.main {
		padding-top: 268px;
		padding-bottom: 55px;
		background-position-y: bottom;
		&-title h1 {
			margin-bottom: 14px;
			font-size: 26px;
			line-height: 35px; }
		&-subtitle {
			font-size: 16px;
			margin-bottom: 19px; } }
	.header {
		padding-top: 8px;
		padding-bottom: 12px;
		&-logo img {
			max-height: 48px; }
		&-phone {
			line-height: 21px;
			a {
				line-height: normal; } }
		.container {
			max-width: none; } }
	.product {
		padding: 40px 20px 43px;
		&s {
			padding-top: 47px;
			padding-bottom: 50px;
			&-tabs {
				margin-bottom: 30px; } }
		&-item {
			margin-bottom: 35px; }
		&-application {
			&__row {
				flex-wrap: nowrap;
				overflow-x: scroll; } } }
	.advantages {
		padding: 41px 0 49px; }
	.delivery {
		padding-top: 41px;
		padding-bottom: 52px;
		&-map {
			width: 690px; } }
	// .point-1
	// 	top: 45px
	// 	left: 169px
	// .point-2
	// 	top: 140px
	// 	left: 105px
	// .point-3
	// 	top: 180px
	// 	left: 137px
	// .point-4
	// 	top: 125px
	// 	left: 216px
	// .point-5
	// 	top: 170px
	// 	left: 293px
	// .point-6
	// 	top: 155px
	// 	right: 210px
	// .point-7
	// 	top: 43px
	// 	right: 98px
	.delivery {
		&-form {
			padding: 40px 30px; }
		.mobile-flex {
			display: flex;
			justify-content: center;
			margin-bottom: 30px; } }
	.work {
		padding-top: 40px;
		padding-bottom: 26px;
		&-col {
			max-width: 363px;
			margin-bottom: 33px;
			&::before {
				display: none; } }
		.line::after {
			width: 2px;
			height: 25px;
			right: 50%;
			bottom: -30px;
			top: auto; } }
	.questions {
		padding-top: 47px;
		padding-bottom: 46px;
		.section-title {
			margin-bottom: 15px; }
		&-subtitle {
			margin-bottom: 38px; } }
	.footer {
		.mobile-flex {
			display: flex;
			align-items: center;
			justify-content: center; }
		&-logo {
			margin-right: 26px; }
		&-text {
			margin-bottom: 12px; }
		&-title {
			margin-bottom: 16px; } } }
@media (max-width: 767px) {
	.section-title {
		font-size: 24px;
		line-height: 28px; }
	.product {
		&-row {
			padding-bottom: 0;
			border-bottom: none; }
		&-application {
			padding-top: 0; }
		&-active {
			margin-bottom: 30px; } }
	.advantages {
		&-item {
			&__descr {
				font-size: 14px; } } }
	.delivery {
		.section-title {
			font-size: 22px;
			text-align: left; }
		&-map {
			width: 100%;
			background-size: cover; }
		&-form {
			padding: 24px 16px 42px;
			&__title {
				font-size: 17px;
				line-height: 28px;
				margin-bottom: 21px; }
			&__item {
				margin-bottom: 25px; } }
		// .point
		// 	width: 11.59px
		// 	height: 17px
		// 	background-size: contain
		/// &-1
		/// 	top: 39px
		/// 	left: 134px
		/// &-2
		/// 	top: 119px
		/// 	left: 83px
		/// &-3
		/// 	top: 147px
		/// 	left: 108px
		/// &-4
		/// 	top: 104px
		/// 	left: 170px
		/// &-5
		/// 	top: 134px
		/// 	left: 231px
		/// &-6
		/// 	top: 125px
		/// 	right: 169px
		/// &-7
		/// 	top: 39px
 }		/// 	right: 77px
	.footer {
		padding-bottom: 0;
		&-logo {
			margin-bottom: 0; }
		&-item {
			margin-bottom: 35px; }
		&-text {
			font-size: 15px; } }
	.label, .form-input {
		font-size: 14px;
		line-height: 18px; } }
@media (max-width: 575px) {
	.main {
		// background: url('../img/main-bg-mobile.png') no-repeat
 }		// background-size: 100% 346px
	.product {
		&-wrap {
			margin-left: -15px;
			margin-right: -15px; }
		&-advantage {
			&__descr {
				font-size: 14px; }
			&__icon {
				margin-right: 14px;
				min-width: 24px; } } }
	.advantages {
		background-position: 45% 50%;
		&-content {
			padding: 33px 20px 30px; }
		&-item {
			flex-direction: column;
			align-items: flex-start;
			&__flex {
				align-items: center;
				margin-bottom: 13px; }
			&__title {
				margin-bottom: 0; } } }
	// .delivery
	// 	&-map
	// 		background-size: cover
	// 		width: 100%
	// 		height: 280px
	// 		margin-bottom: -100px
	// 	.container
	// 		position: relative
	// 	// .point
 }	// 	// 	display: none
@media (max-width: 500px) {
	.delivery {
		&-map {
			height: 400px;
			margin-bottom: -145px; } } }
@media (max-width: 450px) {
	.main {
		&-button {
			.btn {
				width: 100%;
				text-align: center;
				display: block; } } }
	.advantages {}
	.popup {
		padding: 55px 20px 35px; }
	#popup-politic .popup-title {
		font-size: 18px; }
	.fancybox-wrap {
		left: 0 !important; }
	.fancybox-wrap, .fancybox-inner {
		width: 100% !important; } }
@media (max-width: 399px) {
	.delivery {
		&-map {
			height: 300px;
			margin-bottom: -110px; } } }
@media (max-width: 375px) {
	.header {
		.col-auto {
			padding-left: 10px;
			padding-right: 10px; } }
	.work {
		&-col {
			max-width: 100%; } }
	.col-small {
		padding-right: 10px; }
	.col-big {
		padding-left: 10px; } }
@media (max-width: 350px) {
	.product {
		&-characteristics {
			&__item {
				font-size: 14px;
				line-height: 16px;
				padding: 10px 18px 11px 16px; } } }
	.col-size {
		padding-left: 4px;
		padding-right: 4px; } }
